<template>
  <div class="login">
    <!-- <img class="center0" src="@/assets/background.png" alt=""> -->
    <div class="backLogo">
      <img src="@/assets/logoHeader.png" />
    </div>
    <img class="loginImg"
         src="@/assets/loginImg.png" />
    <div class="outBox" :style="{'height': missVerfiy ? '428px' : '368px'}">
      <div class="inBox">
        <img class="loginLogo"
             src="../assets/loginLogo.png" />
        <div class="loginBox">
          <div class="input email">
            <el-input placeholder="请输入邮箱"
                      v-model="email"
                      maxlength="50">
              <i slot="prefix">
                <img class="input_pre youjian"
                     src="../assets/youjian.png" />
              </i>
            </el-input>
          </div>
          <div class="input pass">
            <el-input placeholder="请输入密码"
                      type="password"
                      maxlength="64"
                      v-model="pass">
              <i slot="prefix">
                <img class="input_pre mima"
                     src="../assets/mima.png" />
              </i>
            </el-input>
          </div>
          <div v-show="missVerfiy" class="input verification">
            <el-input placeholder="请输入验证码"
                      type="text"
                      v-model="verification">
              <i slot="prefix">
                <img class="input_pre mima"
                     src="../assets/verfiy.png" />
              </i>
            </el-input>
            <img @click="refreshCaptcha" :src="captcha" class="captcha">
          </div>
          <div class="oprate">
            <p @click="forgetPass"
               class="floatLeft">忘记密码</p>
            <p @click="register"
               class="floatRight">注册账户</p>
            <p style="clear:both;margin:0"></p>
          </div>
          <div>
            <el-button @click="login"
                       class="loginBtn"
                       type="primary">立即接入</el-button>
          </div>
        </div>
      </div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>
<script>
import AppFooter from '@/components/layer/AppFooter'
import { login, getUserInfo } from '@/api/user'
import { sm3 } from '@/utils/sm3'
import { setTimeout } from 'timers'
import { baseURL } from '@/config'
import { setCookie,getCookie,delCookie } from "@/utils"
import { redirectUrl,cookieDomainName } from '@/config'

export default {
  components: {
    AppFooter
  },
  data() {
    return {
      email: '',
      pass: '',
      loading: '',
      verification: '',
      captcha: baseURL + 'api/ump/user/captcha?timestamp=' +new Date().getTime(),
      missVerfiy: false,
      refreshing: false
    }
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    forgetPass() {
      this.$router.push({ path: '/forgetPass' })
    },
    refreshCaptcha() {
      if (this.refreshing) {
        return
      }
      this.captcha = baseURL + '/api/ump/user/captcha?timestamp=' +new Date().getTime()
      this.refreshing = true
      setTimeout(() => { // 防止用户频繁刷新图片验证码，设置2s间隔
        this.refreshing = false
      },2000)
    },
    register() {
      this.$router.push({ path: '/register' })
    },
    login() {
      if (this.email == '') {
        this.$message.error({ message: '邮箱不能为空', customClass: 'loginMes' })
      } else if (this.pass == '') {
        this.$message.error({ message: '密码不能为空', customClass: 'loginMes' })
      } else {
        const data = {
          email: this.email,
          pwd: sm3(this.pass)
        }
        if (this.verification) {
          data.verifyCode = this.verification
        }
        this.loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        login(data).then((res) => {
          this.$message.closeAll()
          this.loading.close()
          if (res.retCode == 0) {
            localStorage.setItem('token', res.token)
            if(location.href.includes(window.toolbox_redirectUrl.open)){ // 测试环境
                setCookie("zxtoken",encodeURIComponent(res.token),cookieDomainName)
            }else{ // 本地
                setCookie("zxtoken",encodeURIComponent(res.token)) // 兼容本地调试
            }
            this.getUserInfo()
          } else {
            if (res.retCode == 3004) {
              this.missVerfiy = true
              this.refreshCaptcha()
            } else {
              this.$message.error({ message: res.retMsg, customClass: 'loginMes' })
              if (res.retCode == 3005) {
                this.refreshCaptcha()
              }
            }
          }
        })
      }
    },
    getUserInfo() {
      if(!localStorage.getItem('token')){
        return
      }
      getUserInfo().then((res) => {
        this.$message.closeAll()
        if (res != undefined) {
          if (res.retCode == 0) {
            localStorage.setItem('userData', JSON.stringify(res.detail))
            const day = new Date()
            day.setTime(day.getTime()+(1*24*60*60*1000))
            const expires = day.toGMTString()
           // document.cookie = 'userData=; expires=Thu, 01 Jan 1970 00:00:00 GMT'
           // document.cookie = 'userData=' + JSON.stringify(res.detail) + ';expires=' + expires + ';domain=.zhixinblockchain.com'
            this.$router.push({ path: '/hashcertificate/overview' })
          } else {
            this.$message.error({ message: res.retMsg, customClass: 'loginMes' })
          }
        } else {
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .login {
    background: url("../assets/background.png");
    background-size: 100% 100%;
    height: 100%;
    ::v-deep .el-menu--horizontal {
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
    ::v-deep .foot {
      position: absolute;
      bottom: 0;
    }
    .loginImg {
      position: absolute;
      top: 50%;

      @media screen and (max-width: 1500px) {
        left: 10%;
      }
      @media screen and (max-width: 2000px) and (min-width: 1500px) {
        left: 22%;
        width: 460px;
        top: 48%;
      }
      margin-top: -199px;
    }
    .backLogo {
      height: 70px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      line-height: 95px;
      img {
        margin-left: 62px;
      }
    }
    .center0 {
      width: 1087px;
      height: 516px;
      margin: -258px auto 0;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -543.5px;
    }
    .outBox {
      @media screen and (max-width: 1500px) {
        right: 150px;
      }
      @media screen and (max-width: 2000px) and (min-width: 1500px) {
        right: 200px;
      }
      width: 416px;
      height: 368px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 8px;
      position: absolute;
      top: 50%;
      margin-top: -201px;
      right: 15%;
      padding: 17px 15px;
      .inBox {
        background: #fff;
        border-radius: 8px;
        width: 100%;
        height: 100%;
        text-align: center;
        .loginLogo {
          width: 250px;
          height: 30px;
          margin: 40px 0;
        }
        .input {
          width: 286px;
          height: 54px;
          margin: 0 auto;
          ::v-deep .el-input input {
            height: 54px;
            line-height: 54px;
          }
          ::v-deep .el-input--prefix .el-input__inner {
            padding-left: 44px;
            border-radius: 8px;
            border: 1px solid rgb(204, 215, 233);
          }
          .input_pre {
            width: 18px;
            height: 21px;
            top: 16px;
            position: relative;
            left: 10px;
          }
          .youjian {
            height: 15px;
            top: 20px;
          }
        }
        .pass,
        .verification {
          margin-top: 12px;
        }
        .verification {
          text-align: left;
          cursor: pointer;
          .el-input {
            width: 60%;
          }
          .captcha{
            height: 40px;
            vertical-align: middle;
            margin-left: 10px;
          }
        }
        .oprate {
          width: 286px;
          margin: 0 auto;
          font-size: 14px;
          color: rgb(153, 153, 153);
          .floatLeft {
            float: left;
            cursor: pointer;
          }
          .floatRight {
            float: right;
            cursor: pointer;
          }
          p {
            margin: 8px 0 23px;
          }
        }
        .loginBtn {
          background: linear-gradient(
            90deg,
            rgba(42, 72, 156, 1),
            rgba(49, 97, 187, 1)
          );
          width: 286px;
          height: 45px;
          border-radius: 4px;
          color: #fff;
          border: none;
        }
      }
    }
  }
</style>
